import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import FormPageWrapper from "../../components/FormPageWrapper";
import Layout from "../../components/Layout";
import { HeadProps, Link, navigate, Script } from "gatsby";
import ButtonPrimary from "../../components/ButtonPrimary";
import {
  useCreateAccountMutation,
  useCurrentUserQuery,
} from "../../features/user/userApiSlice";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { handleErrorResponse } from "../../app/apiSlice";
import { SubmitHandler, useForm } from "react-hook-form";
import FormErrorMessage from "../../components/FormErrorMessage";
import toast from "react-hot-toast";
import Seo from "../../features/seo/Seo";
import { paddleScriptUrl } from "../../constants";
import { initializePaddleScript } from "../../features/checkout/checkoutUtils";
import * as Sentry from "@sentry/gatsby";

interface CreatePasswordFormValues {
  pass: string;
}

const CreatePassword = () => {
  const {
    register,
    formState: { errors },
    setError,
    handleSubmit,
  } = useForm<CreatePasswordFormValues>();

  const checkoutData = useAppSelector((state) => state.checkout);
  const [paddleScriptInitialized, setPaddleScriptInitialized] = useState(false);
  const [isPasswordShowing, setIsPasswordShowing] = useState(false);

  const { data: currentUser, isFetching } = useCurrentUserQuery();

  // Redirect authenticated users to checkout.
  useEffect(() => {
    if (currentUser?.is_authenticated && isFetching === false) {
      navigate("/sign-up/checkout");
    }
  }, [currentUser?.is_authenticated, isFetching]);

  // Redirect anonymous users without an email set to /sign-up.
  useEffect(() => {
    if (currentUser?.is_authenticated === false && checkoutData.email === "") {
      const message = "Please provide an email address";
      toast.error(message);
      const e = Error(message);
      e.name = "password: Please provide email";
      Sentry.captureException(e);
      navigate("/sign-up");
    }
  }, [currentUser?.is_authenticated, checkoutData.email]);

  // Add user to Paddle audience if marketing consent given.
  useEffect(() => {
    if (checkoutData.marketingConsent && paddleScriptInitialized) {
      window.Paddle.Audience.subscribe(
        checkoutData.email,
        checkoutData.marketingConsent,
        (response) => {
          if (response.success !== true) {
            console.error(response.error);
            Sentry.captureMessage(response.error, "warning");
          } else {
            console.log(response.email);
          }
        }
      );
    }
  }, [
    checkoutData.marketingConsent,
    checkoutData.email,
    paddleScriptInitialized,
  ]);

  const [createAccountTrigger, {isLoading}] = useCreateAccountMutation();

  const onSubmit: SubmitHandler<CreatePasswordFormValues> = (values) => {
    createAccountTrigger({
      email: checkoutData.email,
      pass: values.pass.trim(),
      marketingConsent: checkoutData.marketingConsent,
    })
      .unwrap()
      .then((success) => {
        if (typeof gtag === "function") {
          gtag("event", "sign_up", { method: "email" });
        }
        toast.success("Account created. You have been logged in.");
        navigate("/sign-up/checkout");
      })
      .catch((error) => {
        console.error(error);
        const messages = handleErrorResponse(error);
        messages.forEach((message) => {
          const e = new Error(message);
          e.name = "password: onSubmit";
          Sentry.captureException(e);
        });
        setError("pass", {
          type: "api",
          message: messages.pop(),
        });
      });
  };

  return (
    <Layout isCheckout>
      <Script
        src={paddleScriptUrl}
        onLoad={() => {
          initializePaddleScript();
          setPaddleScriptInitialized(true);
        }}
      />
      <FormPageWrapper title="Sign Up">
        <p className="mb-3 sm:mb-6 text-neutral-700">STEP 2 OF 3</p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-7">
            <label
              htmlFor="pass"
              className="block mb-2 font-bold text-xl text-neutral-700"
            >
              Create a password
            </label>
            <div className="relative">
              <input
                className={`border text-neutral-700 shadow py-3 pl-3 pr-10 w-full rounded ${
                  errors.pass ? "border-red-300" : "border-neutral-200"
                }`}
                id="pass"
                type={isPasswordShowing ? "text" : "password"}
                placeholder="Password"
                autoFocus
                {...register("pass", {
                  required: true,
                  minLength: 6,
                  maxLength: 512,
                })}
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    setIsPasswordShowing(!isPasswordShowing);
                  }}
                  type="button"
                >
                  {isPasswordShowing ? (
                    <BsEyeFill className="text-neutral-500 h-5 w-5" />
                  ) : (
                    <BsEyeSlashFill
                      aria-label="Your password is currently hidden. Select this to show your password."
                      className="text-neutral-500 h-5 w-5"
                    />
                  )}
                </button>
              </div>
            </div>
            {errors.pass?.type === "required" && (
              <FormErrorMessage>The password is required.</FormErrorMessage>
            )}
            {errors.pass?.type === "minLength" && (
              <FormErrorMessage>
                Must be at least 6 characters.
              </FormErrorMessage>
            )}
            {errors.pass?.type === "maxLength" && (
              <FormErrorMessage>
                Can not exceed 512 characters.
              </FormErrorMessage>
            )}
            {errors.pass?.type === "api" && (
              <FormErrorMessage>{errors.pass.message}</FormErrorMessage>
            )}
            <p className="text-xs text-neutral-600 mt-2">
              Use a minimum of 6 characters (case sensitive).
            </p>
            <div className="border-l-2 border-neutral-200 pl-4 mt-4">
              <p className="text-neutral-600 text-sm">
                You'll be using this email to log in:
              </p>
              <p>
                <span className="font-bold text-neutral-700">
                  {checkoutData?.email}
                </span>
                <Link
                  className="ml-2 inline-block text-blue-700 hover:text-blue-600 hover:underline active:text-blue-800 text-sm"
                  to="/sign-up"
                >
                  change
                </Link>
              </p>
            </div>
          </div>
          <ButtonPrimary
            size="20"
            type="submit"
            disabled={!checkoutData?.email || isLoading }
          >
            Next
          </ButtonPrimary>
        </form>
      </FormPageWrapper>
    </Layout>
  );
};

export default CreatePassword;

export const Head = (props: HeadProps) => {
  return (
    <>
      <Seo
        title="Choose a password | Giancoli Answers"
        pathname={props.location.pathname}
      />
      <meta name="robots" content="noindex" />
    </>
  );
};
